import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/NoteLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I strongly disagree with the idea that it’s somehow “unproductive” to work
on your blog for a week and publish only one blog post after.`}</p>
    <p>{`I’m not a blogger. I’m a developer. I wanted to build a blog, and I have
one. That’s all. Do I need to write something more? No. It might be “good
for my career”, but I didn’t promise anybody to write blog posts.`}</p>
    <p>{`And you know what’s also totally okay? `}<a parentName="p" {...{
        "href": "/notes/overengineering-a-personal-website",
        "title": "Overengineering a personal\nwebsite"
      }}>{`Overengineering a personal
website`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      